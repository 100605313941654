.header {
  background-color: white;
}
.title {
  font-weight: bolder;
  color: #3c33b0;
  font-size: 28px;
}
.nav_items {
  font-weight: bolder;
  color: black;
}
@media screen and (min-width: 992px) {
  .title {
    padding-left: 1rem;
  }
  .small_device {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .title {
    width: 250px;
    font-size: 18px;
    padding: 0px;
    margin-left: 7px;
  }
  .width {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 991px) {
  .title {
    padding-left: 1.5rem;
  }
  .small_device {
    display: none;
  }
}
