@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
/* ==========================================================================
   Responsive images and box padding
   ========================================================================== */

img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}

* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #fefefe;
}

.container {
  width: 100%;
  margin: 40px auto;
}
h1 {
  font-size: 3em;
  font-weight: 400;
}
